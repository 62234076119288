<po-page-default class="full_screen" p-title="Suporte">

    <!-- FAQ e duvidas - TDN
        Nosso board sobre o ESP - Ryver
        Duvidas e sugestões - Mail:to 
        Inserir o STATUS-ESP quando corrigir o problema do ifram
    -->

    <div class="po-row po-md-lg-sm-12">
        <p class="support_header">STATUS - ESP</p>
        <br>
        <div class="po-row">
            <iframe class="iframe_box" width="1060" height="300" src="https://status.engpro.totvs.com.br/status/esp"></iframe>
        </div>
    </div>
    <po-divider class="full_screen"></po-divider>
    <br>
    <div class="po-row po-md-lg-sm-12">
        <p class="support_header">TDN </p>
        <br>
        <br>
        <div class="po-row">
            <p class="support_text"> - Consulte a documentação dos nossos processos </p> 
            <po-link
                p-label=" aqui!"
                p-open-new-tab="true"
                p-url="https://tdn.totvs.com/x/rz2fKQ" >
            </po-link>
        </div>
    </div>
    <po-divider class="full_screen"></po-divider>
    <br>
    <div class="po-row">
        <p class="support_header">Ryver </p>
        <br>
        <br>
        <div class="po-row">
            <p class="support_text"> - Consulte a board para dúvidas e problemas  </p> 
            <po-link
                p-label=" aqui!"
                p-open-new-tab="true"
                p-url="https://totvs.ryver.com/index.html#forums/1356880" >
            </po-link>
        </div>
    </div>
    <po-divider class="full_screen"></po-divider>
    <br>
    <div class="po-row">
        <p class="support_header">Abrir uma solicitação </p>
        <br>
        <br>
        <div class="po-row">
            <po-input
                class="po-md-12"
                name="ticket_subject"
                [(ngModel)]="ticket_subject"
                p-label="Assunto">
            </po-input>
            <po-textarea 
                class="po-md-12" 
                name="ticket_text" 
                [(ngModel)]="ticket_text" 
                p-label="Conteúdo" 
                p-required 
                p-rows="8">
            </po-textarea>
            <po-button
                class="po-md-2"
                (p-click)="open_ticket()"
                p-kind="primary"
                p-icon="po-icon-mail"
                p-label="Abrir solicitação" >
            </po-button>
        </div>
    </div>
    <po-divider class="full_screen"></po-divider>
    <br>
</po-page-default>
