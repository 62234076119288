import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PoModule } from '@po-ui/ng-components';
import { MainService } from 'src/app/app.service';
import { NotificationServiceHM } from '../../notification-item/notification-item.service';

@Component({
  selector: 'app-share-resource',
  templateUrl: './share-resource.component.html',
  styleUrls: ['./share-resource.component.css'],
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    PoModule]
})
export class ShareResourceComponent {

  @Input() usersSelected: Array<any> = []
  @Input() usersInArea: Array<any> = []
  @Output() saveUserShared = new EventEmitter<{ usersSelected: Array<any> }>();

  loading: boolean = false;

  username: string = '';

  constructor( 
      public mainService: MainService,
      public notificationServiceHM: NotificationServiceHM ) { }

  ngOnChanges(){
  }

  updateSharedUsers() {
    this.saveUserShared.emit({
      usersSelected: this.usersSelected
    });
  }

  checkUser() {
      this.loading = true;
      this.mainService.checkUserValid(this.username)
          .subscribe({
            next: ((response) => {
              if (response['exist'] === true) {
                if( !this.usersSelected.includes(this.username)){
                  this.usersSelected = [...this.usersSelected, this.username]
                }
                
                let filterUsersInArea = this.usersInArea.filter(x => x.label == this.username);
                if( filterUsersInArea.length === 0 ){
                  this.usersInArea = [...this.usersInArea, { label: this.username, value: this.username}]
                }
                
                this.updateSharedUsers();
              } else {
                this.notificationServiceHM.showToast('Usuário não encontrado!', 'error')
              }
              this.loading = false;
            }),
            error: ((msgError) => {
              this.mainService.handleError(msgError);
              this.loading = false;
            })
          });      
    }
}
