import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatabaseComponent } from './database.component';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { PoModule } from '@po-ui/ng-components';
import { PoTemplatesModule } from '@po-ui/ng-templates';
import { DatabaseService } from './database.service';
import { MainService } from 'src/app/app.service';
import { NotificationItemComponent } from '../notification-item/notification-item.component';
import { RenameExtendComponent } from "../shared/rename-extend/rename-extend.component";
import { ShareResourceComponent } from "../shared/share-resource/share-resource.component";
import { DatabaseViewComponent } from './components/database-view/database-view.component';
import { FilterScreenComponent } from '../shared/filter-screen/filter-screen/filter-screen.component';
@NgModule({
  declarations: [
    DatabaseComponent,
    DatabaseViewComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    AppRoutingModule,
    PoModule,
    PoTemplatesModule,
    NotificationItemComponent,
    RenameExtendComponent,
    ShareResourceComponent,
    FilterScreenComponent
  ],
  providers: [
    MainService,
    DatabaseService
  ]
})
export class DatabaseModule { }
