import { Component, OnInit, ViewChild } from '@angular/core';
import { JamesbService } from './jamesb.service';
import { MainService } from 'src/app/app.service';
import { JamesB } from '../../classes/JamesB';
import { PoModalAction, PoModalComponent, PoNotificationService, PoTableColumn } from '@po-ui/ng-components';
import { Subject, takeUntil } from 'rxjs';
import { NodeWithI18n } from '@angular/compiler';
import { MatomoTracker } from '@ngx-matomo/tracker';

@Component({
  selector: 'app-jamesb',
  templateUrl: './jamesb.component.html',
  styleUrls: ['./jamesb.component.css']
})
export class JamesbComponent implements OnInit {

  @ViewChild('criajamesb', {static: false}) criaJamesbModal: PoModalComponent;

  constructor(
    public mainservice: MainService, 
    public jamesbService: JamesbService,
    private poNotification: PoNotificationService,
    private readonly tracker: MatomoTracker) { }

  unsubscribe$ = new Subject<void>();

  jamesBColumns: Array<PoTableColumn>  = [
    { property: 'username', label: 'Usuário', type: 'string' },
    { property: 'remote', label: 'Máquina', type: 'string' },
    { property: 'server', label: 'Server', type: 'string' },
    { property: 'reason', label: 'Motivo', type: 'string' },
    { property: 'area', label: 'Area', type: 'string' },
    { property: 'expireDate', label: 'Expira em', type: 'string'},
    { property: 'approved', label: 'Aprovado?', type: 'boolean' },
  ];
  jamesBItem: Array<any> = [];
  jamesBItemDel: Array<any> = [];

  _username: string = '';
  _machineUser: string = '';
  _remote: string = '';
  _server: string = '';
  _reason: string = '';
  _area: string = '';
  _isAreaBound: string = '';
  _approved: string = '';
  _exp: string = '';

  confirm: PoModalAction = {
    action: () => {
      this.criarJamesb();
    },
    label: 'Create!'
  }

  close: PoModalAction = {
    action: () => {
      this.closeCreateModal();
    },
    label: 'Close',
    danger: true
  };

  loading = false;
  isAdmin = false;

  ngOnInit() {
    this.tracker.trackPageView('JamesBond');
    this.getJamesB();
    this._username = this.mainservice.userToken.username;
    this._machineUser = this.mainservice.userToken.username;
    if ( this.mainservice.userToken.role === 'superuser') {
      this.isAdmin = true;
    }
  }

  getJamesB() {
    this.jamesBItem = [ ];
    this.jamesbService.getLiberacoes()
    .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: ((retorno) => {
          retorno.forEach(element => {
            const jamesBTemp = new JamesB();
            const username = element.username;
            element.jbRequests.forEach( subElement => {
              jamesBTemp.username = username;
              jamesBTemp.remote = subElement.remote;
              jamesBTemp.server = subElement.server;
              jamesBTemp.reason = subElement.reason;
              jamesBTemp.area = subElement.area;
              jamesBTemp.expireDate = subElement.expirationDate.replace('T', ' - ').split('.', 1);
              jamesBTemp.isAreaBound = subElement.isAreaBound;
              jamesBTemp.approved = true;
              this.jamesBItem = [...this.jamesBItem, jamesBTemp];
              const emailLabel = jamesBTemp.username;
              this.jamesBItemDel = [...this.jamesBItemDel, { label: emailLabel, value: jamesBTemp.username }];
            })
            
          });
        }),
        error: ((msgError) => {
          this.mainservice.handleError(msgError);
          this.loading = false;
        })
      });  
  }

  criarJamesb() {
      
      this._area = this.mainservice.userToken.area;
      this._server = this._remote;
      
      const dateDiff = this.convertDate(this._exp)
      if (this._username === '' || this._machineUser=== '' || this._remote === '') {
        this.poNotification['error'](`Email, usuário ou Nome da máquina(Hostname) não podem estar vazios!`);
      } else {
        this.loading = true;
        this.jamesbService.postLiberacao(this._username, this._machineUser, this._remote, this._server, this._reason, this._area, dateDiff)
          .subscribe(res => {
            this.getJamesB();
            this.loading = false;
          });

        this.closeCreateModal();
        this.loading = false;
      }
      
  }

  convertDate(data) {
    const values = data.split('/', 3);
    let date = new Date(values[2], (values[1]-1), values[0]);
    let dateDiff = Math.abs(date.getTime() - new Date().getTime());
    dateDiff = Math.ceil(dateDiff / (1000 * 3600 * 24));

    return dateDiff;
  }
  closeCreateModal() {
    this.criaJamesbModal.close();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  openJbDoc() {
    window.open('https://tdn.totvs.com/pages/releaseview.action?pageId=781198492', '_blank');
  }

}
