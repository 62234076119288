// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  BQA_REFRESH_CHART: 60000,
  API: "https://backend.221b.engpro.totvs.com.br/cli/",
  url_v1 : 'https://api-v1.esp.pre.engpro.totvs.com.br/api/v1',
  url_v1_v2 : 'https://api-v1.esp.pre.engpro.totvs.com.br/api/v2',
  url_v2 : 'https://api-v2.esp.pre.engpro.totvs.com.br/api/v2',
  API_CHAT: 'https://tqa-chatbot.esp.pre.engpro.totvs.com.br/chat'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
