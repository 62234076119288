import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, retry } from 'rxjs';
import { MainService } from 'src/app/app.service';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {

  constructor(private _http: HttpClient, private mainService: MainService) { }

    getDatabases (): Observable<any> {
      let resources = this._http.get('/api/v1/resources?fields=all', this.mainService.httpOptions).pipe(
          retry(3),
          map(instancias => {
             return instancias;
          }),
          catchError(this.mainService.handleError)
      );

      let databases = this._http.get('/api/v2/databases/details', this.mainService.httpOptions).pipe(
          retry(3),
          map(instancias => {
             return instancias;
          }),
          catchError(this.mainService.handleError)
      );

      return new Observable((subscriber) => {
        resources.subscribe((data) => {
          let data2 = data as Array<{kind?: string}>;
          for (const i of data2) i.kind = 'resource';
          subscriber.next(data2);
        });

        databases.subscribe((data) => {
          let data2 = data as Array<{kind?: string}>;
          for (const i of data2) i.kind = 'database';
          subscriber.next(data2);
        });
      });
    }

    getAllDatabases (): Observable<any> {
        return this._http.get('/api/v1/resources?view=all', this.mainService.httpOptions )
                .pipe(
                        retry(3),
                        map(instancias => {
                            return instancias;
                        }),
                        catchError(this.mainService.handleError));
    }

    getDatabases_v1 (): Observable<any> {
        return this._http.get('/api/v1/resources?fields=all', this.mainService.httpOptions )
                .pipe(
                        retry(3),
                        map(instancias => {
                            return instancias;
                        }),
                        catchError(this.mainService.handleError));
    }

    getRecipesByType(recipeType): Observable<any> {
      let recipes = this._http.get(`/api/v1/recipes?taskType=create&spotType=${recipeType}`, this.mainService.httpOptions ).pipe(
        retry(3),
        map(data => {
          return data;
        }),
        catchError(this.mainService.handleError)
      );

      let templates = this._http.get(`/api/v2/templates`, this.mainService.httpOptions ).pipe(
        retry(3),
        map(data => {
          return data;
        }),
        catchError(this.mainService.handleError)
      );

      return new Observable((subscriber) => {
        recipes.subscribe((data) => {
          let data2 = data as Array<{kind?: string}>;
          for (let item of data2) item.kind = "recipe";
          subscriber.next(data2);
        });

        templates.subscribe((data) => {
          let data2 = data as Array<{kind?: string}>;
          for (let item of data2) item.kind = "template";
          subscriber.next(data2);
        });
      });
    }

    getSoftwarebyId (id): Observable<any> {
        return this._http.get('/api/v1/software/'+ id, this.mainService.httpOptions )
                    .pipe(
                            retry(3),
                            map(files => {
                                return files;
                            }),
                            catchError(this.mainService.handleError));
    }

    getSoftware(): Observable<any> {
        return this._http.get('/api/v1/software', this.mainService.httpOptions )
                    .pipe(
                            retry(3),
                            map(software => {
                                return software;
                            }),
                            catchError(this.mainService.handleError));
      }

    createDatabase(name, expirationDays, recipeId, password, filename) {
        let bucket = (filename) ? this.mainService.userToken.username : '';

	let payload = JSON.stringify({
            name,
            expirationDays,
            recipeId,
            password,
            bucket,
            filename
        });

        return this._http.post('/api/v1/resources', payload, this.mainService.httpOptions).pipe(
            map(res => {
                return res;
            }),
            catchError(this.mainService.handleError)
        );
    }

    createDatabase2(name, expirationDays, templateId, password, filename) {
        let bucket = (filename) ? this.mainService.userToken.username : '';

	let payload = JSON.stringify({
            name,
            expirationDays,
            templateId,
            password,
            bucket,
            filename
        });

        return this._http.post('/api/v2/databases', payload, this.mainService.httpOptions).pipe(
            map(res => {
                return res;
            }),
            catchError(this.mainService.handleError)
        );
    }


    getDatabaseName(id: string, resources: Array<any>) {
        let instIndex;
        let instName;
        instIndex = resources.find(obj => obj.Id === id);
        instIndex = resources.indexOf(instIndex);
    
        if ( instIndex > -1 ) {
            instName = resources[instIndex].Name;
        } else {
            instName = 'NOT FOUND';
        }
        return instName;
    }

    deleteDatabase (id): Observable<any> {
        return this._http.delete('/api/v1/resources/' + id, this.mainService.httpOptions)
        .pipe(map(res => {
            return res;
        }),
            catchError(this.mainService.handleError)
        );
    }

    deleteDatabase2 (id): Observable<any> {
        return this._http.delete(`/api/v2/databases/${id}`, this.mainService.httpOptions)
        .pipe(map(res => {
            return res;
        }),
            catchError(this.mainService.handleError)
        );
    }

    alterarDatabase(id, name, expirationDays): Observable<any> {
        this.mainService.updateHeaders();
    
        return this._http.put('/api/v1/resources/' + id, JSON.stringify({name, expirationDays}), this.mainService.httpOptions)
            .pipe(
                map( res => {
                    return res;
                }),
                    catchError(this.mainService.handleError)
            );
    }

    getActionsbyResource (resourceId): Observable<any> {
        return this._http.get('/api/v1/actions?resourceId=' + resourceId, this.mainService.httpOptions )
                    .pipe(
                            retry(3),
                            map(actions => {
                                return actions;
                            }),
                            catchError(this.mainService.handleError));
    }


    restartDbAccess (id): Observable<any> {
        return this._http.post('/api/v1/resources/restartdbaccess/' + id , JSON.stringify({ id }), this.mainService.httpOptions)
            .pipe(
                map(res => {
                    return res;
            }),
                catchError(this.mainService.handleError)
            );
    }

    restartDbAccess2 (id): Observable<any> {
        return this._http.get(`/api/v2/databases/${id}/restart/` , this.mainService.httpOptions)
            .pipe(
                map(res => {
                    return res;
            }),
                catchError(this.mainService.handleError)
            );
    }

    backupDatabase (id): Observable<any> {
        return this._http.post('/api/v1/resources/backup/' + id , JSON.stringify({ id }), this.mainService.httpOptions)
            .pipe(
                map(res => {
                    return res;
            }),
                catchError(this.mainService.handleError)
            );
    }

    backupDatabase2(id): Observable<any> {
        return this._http.post(`/api/v2/databases/${id}/backup`, JSON.stringify({ id }), this.mainService.httpOptions)
            .pipe(
                map(res => {
                    return res;
            }),
                catchError(this.mainService.handleError)
            );
    }

    restoreDatabase(id, backup): Observable<any> {
        return this._http.post('/api/v1/resources/restore/' + id , JSON.stringify({ id, backup }), this.mainService.httpOptions)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.mainService.handleError)
            );
    }

    restoreDatabase2(id, filename): Observable<any> {
        return this._http.post(`/api/v2/databases/${id}/restore` , JSON.stringify({ id, filename }), this.mainService.httpOptions)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.mainService.handleError)
            );
    }

    shareDatabase(id, usersToShare): Observable<any> {
        return this._http.post('/api/v1/resources/share/' + id, JSON.stringify({ usersToShare }), this.mainService.httpOptions)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.mainService.handleError)
            );
    }

    changePasswordDatabase (id, password): Observable<any> {
        this.mainService.updateHeaders();
    
        return this._http.post('/api/v1/resources/password/' + id, JSON.stringify({password}), this.mainService.httpOptions)
            .pipe(
                map( res => {
                    return res;
                }),
                    catchError(this.mainService.handleError)
            );
    }

    changePasswordDatabase2 (id, pass): Observable<any> {
        this.mainService.updateHeaders();

        return this._http.put(`/api/v2/databases/${id}/password/`, JSON.stringify({id, pass}), this.mainService.httpOptions)
            .pipe(
                map( res => {
                    return res;
                }),
                    catchError(this.mainService.handleError)
            );
    }

    public uploadImage(file: File, filename: string): Observable<any> {

        const formData = new FormData();
        const httpOptions = {
            headers : new HttpHeaders ({
                'Authorization': this.mainService.userToken.token
                })
            };
        formData.append('file', file);

        return this._http.post('/api/v1/files?filename=' + filename, formData, httpOptions)
            .pipe(
                map(res => {
                    return res;
            }),
                catchError(this.mainService.handleError)
            );
    }

    public getFiles (): Observable<any> {
        return this._http.get('/api/v1/files', this.mainService.httpOptions )
                    .pipe(
                            retry(3),
                            map(files => {
                                return files;
                            }),
                            catchError(this.mainService.handleError));
    }

    public deleteFile (name): Observable<any> {
        return this._http.delete('/api/v1/files/' + name, this.mainService.httpOptions)
        .pipe(
            catchError(this.mainService.handleError)
        );
    }

    public downloadFile (name): Observable<any> {

        return this._http.get('/api/v1/files/url/' + name , this.mainService.httpOptions)
                .pipe(
                    retry(3),
                    map(files => {
                        return files;
                    }),
                    catchError(this.mainService.handleError));
    }

}
