<po-page-list 
  p-title="Liberações - JamesBond"> 
  
    <div class="po-row">

      <po-button
        class="po-lg-3 po-mt-3"
        (p-click)="criajamesb.open()"
        p-label="Solicitar Liberação" >
      </po-button>

      <po-button
        class="po-lg-3 po-mt-3"
        (p-click)="openJbDoc()"
        p-label="Abrir Documentação" >
      </po-button>

    </div>

  <po-divider></po-divider>
  
  <po-container class="po-lg-12">
    <po-table
          p-container
          p-height="400"
          [p-columns]="jamesBColumns"
          [p-items]="jamesBItem"
          p-sort="true"
          [p-striped]="true">
    </po-table>
  </po-container>

  <div class="sample-container" *ngIf="loading">
    <po-loading-overlay></po-loading-overlay>
  </div>
    
</po-page-list>

<po-modal #criajamesb
    p-title="Nova Liberação"
    [p-primary-action]="confirm"
    [p-secondary-action]="close">


  <div class="po-row">
    <po-input
      class="po-md-5"
      name="jamesbemail"
      p-clean
      p-label="Email"
      p-required="true"
      [p-disabled]="!isAdmin"
      [(ngModel)]="_username">
    </po-input>
  </div>

  <div class="po-row">
    <po-input
      class="po-md-5"
      name="jamesbMachineUser"
      p-clean
      p-required="true"
      p-label="Usuário da máquina"
      [(ngModel)]="_machineUser">
    </po-input>
  </div>

  <div class="po-row">
    <po-input
      class="po-md-5"
      name="jamesbmachine"
      p-clean
      p-required="true"
      p-label="Nome da Máquina(Hostname)"
      [(ngModel)]="_remote">
    </po-input>
  </div>

  <div class="po-row">
    <po-textarea
      class="po-md-5"
      name="jamesbreason"
      p-label="Motivo"
      [(ngModel)]="_reason">
    </po-textarea>
  </div>

  <div class="po-row" *ngIf="isAdmin">
    <po-number
      class="po-md-5"
      name="jamesbexp"
      p-help="Número de dias desejados"
      p-clean
      p-label="Expiração"
      [p-disabled]="!isAdmin"
      [(ngModel)]="_exp">
    </po-number>
  </div>



</po-modal>