<div class="po-row">
    <po-info p-label="Usuários compartilhados" class="po-md-12" [p-value]="usersSelected" ></po-info>
  </div>

  <div class="po-row align">
    <po-input
      name="usersSelected"
      class="po-md-8"
      [(ngModel)]="username"
      label="Usuário para compartilhar"
      p-placeholder="Informe o nome do usuário que deseja compartilhar"
      p-required>
    </po-input>

    <po-button
      class="po-md-4"
      (p-click)="checkUser()"
      p-label="Adicionar" >
    </po-button>

  </div>

  <div class="po-row">
    <po-multiselect
      name="usersSelected"
      class="po-md-12"
      [(ngModel)]="usersSelected"
      label="Usuários"
      [p-options]="usersInArea"
      (p-change)="updateSharedUsers()"
      p-required>
    </po-multiselect>

  </div>

  <div class="sample-container" *ngIf="loading">
    <po-loading-overlay></po-loading-overlay>
  </div>