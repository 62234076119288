
    <div class="po-row">
        <po-info p-label="ID" class="po-md-3" [p-value]="databaseItem.id"></po-info>
        <po-tag p-label="Status" class="po-md-2" p-orientation="vertical" [p-type]="databaseItem.statusType" [p-value]="databaseItem.status"></po-tag>
        <po-info p-label="Expiração (dias)" class="po-md-2" [p-value]="databaseItem.remainingTime" ></po-info>
        <po-info p-label="Software" class="po-md-2" [p-value]="databaseItem.softwareType"></po-info>
    </div>

    <br>

    <div class="po-row" *ngIf="databaseItem.kind !== 'database'">
        <po-info p-label="Database IP" class="po-md-2" [p-value]="databaseItem.dbipporta" ></po-info>
        <po-info p-label="Username" class="po-md-3" [p-value]="databaseItem.username" ></po-info>
        <po-info p-label="DBAccess IP" class="po-md-2" [p-value]="databaseItem.dbaccess" ></po-info>
        <po-info p-label="DB Alias" class="po-md-2" [p-value]="databaseItem.alias" ></po-info>
        <po-info p-label="DBAPI:" class="po-md-2" p-value="Baixar versão atual" p-url="https://arte.engpro.totvs.com.br/engenharia/devops/artefatos/dbapi-esp-dbaccess/dbapi-esp-dbaccess.zip" ></po-info>
    </div>

    <div *ngIf="databaseItem.kind === 'database'">
        <div class="po-row">
            <po-info p-label="Database IP" class="po-md-3" [p-value]="databaseItem.dbipporta" ></po-info>
            <po-info p-label="Username" class="po-md-3" [p-value]="databaseItem.username" ></po-info>
            <po-info p-label="Password" class="po-md-3 hiddenPassword" [p-value]="databaseItem.visualPassword" (click)="displayPassword(databaseItem)" title="click to show password"></po-info>
        </div>
        <br>
        <div class="po-row">
            <po-info p-label="DBAccess IP" class="po-md-3" [p-value]="databaseItem.dbaccess" ></po-info>
            <po-info p-label="Alias" class="po-md-3" [p-value]="databaseItem.alias" ></po-info>
            <po-info p-label="DBApi:" class="po-md-3" p-value="Baixar versão atual" p-url="https://arte.engpro.totvs.com.br/engenharia/devops/artefatos/dbapi-esp-dbaccess/dbapi-esp-dbaccess.zip" ></po-info>
        </div>
    </div>

    <br>

    <div class="po-row">
      <po-info p-label="Proprietário: " class="po-md-2" [p-value]="databaseItem.owner" ></po-info>
      <po-info p-label="Usuários compartilhados" class="po-md-7" [p-value]="databaseItem.shared" ></po-info>
      <po-info *ngIf="databaseItem.active === true" p-label="Criado em: " class="po-md-3" [p-value]="databaseItem.createdAt" ></po-info>
      <po-info *ngIf="databaseItem.active === false" p-label="Deletado em: " class="po-md-3" [p-value]="databaseItem.updatedAt" ></po-info>
    </div>
    
