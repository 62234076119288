<br>
        <div class="button-container" >
          <po-select
            class="filterInstancesSelect po-md-lg-sm-4"
            name="filterOptionsSelect"
            [(ngModel)]="filterSelected"
            (p-change)="onChangeFilterSelect($event)"
            p-label="Filtro"
            [p-options]="filterOptionsSelect"
            >
          </po-select>  
            
          <po-button
            class="filterInstancesInvertButton po-md-lg-sm-1"
            name="filterInstancesInvertButton"
            (p-click)="invertFilterOrder()"
            [p-icon]="templateButton"
            p-kind="tertiary"
          >
          </po-button> 

          <ng-template #templateButton>
            <span class="po-icon po-icon-sort" style="font-size: 30px;" ></span>
          </ng-template>

          <po-checkbox-group class="filterInstancesChkGroup po-md-lg-sm-4"
            name="filterInstancesChkGroup"
            p-label="Status"
            (p-change)="filterInstances(filterInstancesModel)"
            [(ngModel)]="filterInstancesModel"
            [p-indeterminate]="true"
            [p-columns]="3"
            [p-options]="filterOptions"
            >
          </po-checkbox-group>  
        </div> 