<head>
  <!-- Matomo Tag Manager -->
  <script>
    var _mtm = window._mtm = window._mtm || [];
    _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src='http://10.171.80.34:9603/js/container_akjXKwvF.js'; s.parentNode.insertBefore(g,s);
    </script>
    <!-- End Matomo Tag Manager -->
  </head>
<po-page-default p-title="Databases">

    <div class="po-row">

      <po-button
        class="po-md-2"
        (p-click)="resetValuesCriaDb(); criadb.open()"
        p-kind="primary"
        p-icon="po-icon-database"
        p-label="Criar Database" >
      </po-button>

      <po-button
        class="po-md-2"
        (p-click)="updateScreenData()"
        p-label="Atualizar"
        p-icon="po-icon-refresh">
      </po-button>

      <po-button
        class="po-md-2"
        (p-click)="resetValuesUpload(); uploadModal.open()"
        p-label="Meus Backups"
        p-icon="po-icon-upload">
      </po-button>

      <po-button
        class="po-md-2"
        (p-click)="toggleFilters();"
        p-label="Filtros"
        p-icon="po-icon-filter">
      </po-button>

    </div>

    <div class="container po-row filterInstancesRow" *ngIf="filterToggle">
      <br>
        <app-filter-screen
          origin="database"
          [originalData]="databases"
          [activeOptions]="activeOptions"
          [deleteOptions]="deleteOptions"
          [filterOptionsSelect]="filterOptionsSelect"
          (filteredDataEmitter)="filteredData($event)"
        >
        </app-filter-screen>
    </div>

    <po-divider></po-divider>
    <po-list-view
      p-hide-select-all
      [p-actions]="itemListOptions"
      [p-items]="databasesView"
      p-property-title="name">

      <ng-template p-list-view-content-template let-item >
        <app-database-view [databaseItem]="item"></app-database-view>
      </ng-template>

    </po-list-view>

    <notificationComponentHM></notificationComponentHM>

    <div class="sample-container" *ngIf="loading">
      <po-loading-overlay></po-loading-overlay>
    </div>

  </po-page-default>

<po-modal #criadb
  p-title="Novo Database"
  [p-primary-action]="dbCreate"
  [p-secondary-action]="cancelCreate"
  >

  <p class="infoLabel" style="font-weight: bold;">ATENÇÃO!</p>
  <p class="infoLabel">Todas as novas instancias são criadas com apenas 7 dias de duração, podendo ser prorrogadas como antes.</p>

  <div class="po-row">

    <po-input
      class="po-md-9"
      name="Nome"
      [(ngModel)]="_name"
      p-label="Nome">
    </po-input>

    <po-select
      class="po-lg-md-sm-3 "
      name="softwares"
      [(ngModel)]="softwareSelected"
      p-label="DB Software"
      (p-change)="onChangeSoftware($event)"
      [p-required]="true"
      [p-options]="software">
    </po-select>

  </div>

  <div class="po-row">
    <po-select
      class="po-lg-md-sm-6 receitasSelect"
      name="receitas"
      [(ngModel)]="receitaID"
      (p-change)="onChangeRecipe($event)"
      p-label="Receitas"
      [p-required]="true"
      [p-disabled]="disableRecipe"
      [p-options]="receitasFiltered">
    </po-select>

    <po-select
      class="po-lg-md-sm-6"
      name="backups"
      [(ngModel)]="chosenBackup"
      p-label="Meus Backups"
      [p-required]="true"
      [p-disabled]="disableRestore"
      [p-options]="backups">
    </po-select>

  </div>

  <div class="po-row">
    <po-password
    class="po-sm-12"
    name="password"
    [p-disabled]="!showPassword"
    [(ngModel)]="_password"
    (p-change-model)="checkPassword($event)"
    p-clean
    p-help="Guarde essa senha! Ela será utilizada para acesso ao banco e não será armazenada no ESP (min. 5 caracteres, sem * / - _ . ,)"
    p-label="Senha"
    p-minlength="5"
    p-pattern="[a-zA-Z0-9@%?.,]"
    p-error-pattern="Padrão de senha invalido. Os simbolos permitidos são @ % ?"
    p-required>
  </po-password>
  </div>



  <div class="sample-container" *ngIf="loading">
    <po-loading-overlay></po-loading-overlay>
  </div>
</po-modal>

<po-modal #deletDatabase
    p-title="Excluir Database"
    [p-primary-action]="okDelete"
    [p-secondary-action]="cancelDelete">

    <div class="po-row">

      <po-select
        class="po-lg-6"
        name="delete"
        p-label="Deseja excluir a database?"
        [p-required]="true"
        [(ngModel)]="canDelete"
        [p-options]="[{label: 'Não', value: 'NO'}, {label: 'Sim', value: 'YES'}]">
      </po-select>

    </div>

</po-modal>

<po-modal #renameDatabase
    p-title="Prorrogar/Renomear Database"
    [p-primary-action]="modificaDatabase">

    <div class="sample-container" *ngIf="loading">
      <po-loading-overlay></po-loading-overlay>
    </div>

    <app-rename-extend
        [name]="_name"
        [expirationDays]="expiraNew"
        [expiraOptions]="expiraOptions"
        (saveData)="getUpdateData($event)">
    </app-rename-extend>

</po-modal>

<po-modal #backupRestore
  p-title="Restore do Database"
  [p-primary-action]="okRestore"
  [p-secondary-action]="cancelRestore">

  <div class="po-row">
    <po-select
      class="po-lg-6"
      name="backups"
      [(ngModel)]="chosenBackup"
      p-label="Backups disponíveis"
      [p-required]="true"
      [p-options]="backups">
    </po-select>
  </div>

</po-modal>

<po-modal #historyModal
  [p-title]="titleDetailsModal"
  [p-primary-action]="okHistory">

  <div class="sample-container" *ngIf="loading">
    <po-loading-overlay></po-loading-overlay>
  </div>

  <po-table
    [p-columns]="columnsDetails"
    [p-items]="history">
  </po-table>

</po-modal>

<po-modal #shareModal
    p-title="Compartilhar Database"
    [p-primary-action]="okShare"
    [p-secondary-action]="cancelShare">

    <div class="sample-container" *ngIf="loading">
      <po-loading-overlay></po-loading-overlay>
    </div>

    <app-share-resource
      [usersSelected]="usersSelected"
      [usersInArea]="usersInArea"
      (saveUserShared)="getUpdateSharedData($event)">
    </app-share-resource>

</po-modal>

<po-modal #changePassword
  p-title="Trocar Senha do Database"
  [p-primary-action]="okChangePassword"
  [p-secondary-action]="cancelChangePassword">

  <div class="po-row">
    <po-info p-label="Insira a nova senha para a database:" class="po-md-6"></po-info>
  </div>
  <div class="po-row">
    <po-password
      class="po-md-12"
      name="Senha"
      [(ngModel)]="passwordNew"
      p-label="Senha">
    </po-password>
  </div>
</po-modal>

<po-modal #uploadModal
    p-title="Meus Backups"
    [p-primary-action]="sendUpload"
    [p-secondary-action]="cancelUpload">

    <po-table
      [p-columns]="uploadColumns"
      [p-items]="uploadItems"
      [p-actions]="uploadActions">
    </po-table>

    <po-divider></po-divider>

    <div class="sample-container" *ngIf="canUpload">
      <po-upload #upload
        class="po-md-12"
        name="bakcup"
        [(ngModel)]="selectedFile"
        p-label="Selecione o arquivo com a imagem do banco:"
        p-hide-send-button
        p-url="https://api.esp.engpro.totvs.com.br/api/v1/files"
        (p-error)="resumeUploadError()"
        (p-success)="resumeUploadSuccess()">
      </po-upload>
    </div>

    <div class="sample-container" *ngIf="!canUpload">
      <po-info
        p-label="Limite máximo atingido!"
        p-value="Você atingiu o limite de 4 arquivos. Para novos uploads você deve apagar arquivos já existentes." >
      </po-info>
    </div>

    <div class="sample-container" *ngIf="loading">
      <po-loading-overlay></po-loading-overlay>
    </div>

</po-modal>
