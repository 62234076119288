import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Issue } from '../models/issue.model';
import { catchError, map, retry } from 'rxjs';
import { MainService } from 'src/app/app.service';

@Injectable({
  providedIn: 'root'
})
export class IssueService {

  private readonly API = environment.API;

  constructor(private http: HttpClient, private mainsService: MainService) { }

  /* Métodos para criação de issues */
  createIssue(issue: Issue) {
    return this.http.get<any>(`${this.API}create-issue`, {
      params: {
        '_id': String(issue._id),
        'squad': String(issue.squad),
        'source': String(issue.source),
        'function': String(issue.func),
        'line': String(issue.line),
        'query': String(issue.query),
        'modulo': String(issue.modulo),
        'planning': String(issue.planning),
        'area': String(issue.area),
        'user': String(issue.user),
        '_smarttest': String(issue._smarttest),
        // 'advplerrors': String(issue.advplerrors),
        // 'dbmserrors': String(issue.dbmserrors)
      }
    })
      .pipe(
        retry(3),
        map(issue => {
            return issue;
        }),
        catchError(this.mainsService.handleError)
      );
  }

  checkIssue(issuecode: String, queryid: String) {
    return this.http.get<any>(`${this.API}check-issue`, {
      params: {
        'id': String(queryid),
        'issuecode': String(issuecode),
      }
    })
      .pipe(
        retry(3),
        map(issue => {
            return issue;
        }),
        catchError(this.mainsService.handleError)
      );
  }

}