import { Component, Inject, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { PoListViewAction, PoModalAction, PoModalComponent, PoNotification, PoNotificationService, PoSelectOption, PoSelectOptionGroup, PoTableAction, PoTableColumn, PoTagType, PoUploadComponent } from '@po-ui/ng-components';
import { Subject, takeUntil } from 'rxjs';
import { saveAs } from 'file-saver';
import { MainService } from 'src/app/app.service';
import { Actions } from 'src/app/classes/Actions';
import { Database } from 'src/app/classes/Database';
import { Files } from 'src/app/classes/Files';
import { Recipes } from 'src/app/classes/Recipes';
import { Software } from 'src/app/classes/Software';
import { DatabaseService } from './database.service';
import { MatomoTracker } from '@ngx-matomo/tracker';
import { NotificationServiceHM } from '../notification-item/notification-item.service'

@Component({
  selector: 'app-database',
  templateUrl: './database.component.html',
  styleUrls: ['./database.component.css']
})
export class DatabaseComponent implements OnInit {

  @ViewChild('criadb', {static: false}) criaDBModal: PoModalComponent;
  @ViewChild('deletDatabase', {static: false}) deleteDBModal: PoModalComponent;
  @ViewChild('renameDatabase', {static: false}) renameDBModal: PoModalComponent;
  @ViewChild('backupRestore', {static: false}) restoreModal: PoModalComponent;
  @ViewChild('historyModal', {static: false}) historyModal: PoModalComponent;
  @ViewChild('shareModal', {static: false}) shareModal: PoModalComponent;
  @ViewChild('changePassword', {static: false}) changePasswordModal: PoModalComponent;
  @ViewChild('uploadModal', {static: false}) uploadModal: PoModalComponent;
  @ViewChild('upload', {static: false}) upload: PoUploadComponent;

  showPassword: boolean = true;

  loading = false;
  unsubscribe$ = new Subject<void>();

  activeOptions: Array<string> = ['created', 'creating', 'active', 'deployed', 'deploying', 'expired', 'expiring', 'creating database', 'deploying dbaccess'];
  deleteOptions: Array<string> = ['deleted', 'deleting', 'failed'];
  cannotModifyOptions: Array<string> = ['creating', 'created', 'deleting', 'deleted', 'failed', 'creating database', 'deploying dbaccess'];

  filterOptionsSelect: Array<any> = [
    { label: "Nome", value: "name"  },
    { label: "Status", value: "nStatus"  },
    { label: "Data de Criação", value: "createdAt"  },
    { label: "Expiração", value: "remainingTime"  },
  ]
  filterSelected: string = '';
  ascendingDescending: boolean = true;
  filterToggle: boolean = false;

  databases: any[] = [];
  databasesView: any[] = [];
  uploadItems: any[] = [];
  canUpload: boolean = false;
  backups: any[] = [];
  chosenBackup: string = '';

  // - NOTIFICATIONS
    message: string = '';
    poNotification: PoNotification = {
      message: this.message,
      action: undefined
    };

  // - VARIAVEIS BASE
  _id: string = '';
  _name: string = '';
  _password: string = '';
  _passwordValid: boolean = false;
  _status: string = '';
  _statusType: string = '';
  _remainingTime: number = 0;
  _expirationDate: string = '';
  _username: string = '';
  _area: string = '';
  _nStatus: number = 0;
  _login: string = '';
  _softwareType: string = '';
  _owner: string = '';
  _shared: string[] = [''];
  _metadata: object = {};
  _alias: string = '';
  _dbipporta: string = '';
  _dbaccess: string = '';
  _instanceType: string = '';

  // REGRA EXCEPCIONAL PARA MIGRAÇÃO DO R22
  ___SPOTIDTOBLOCK = ["5cb876b8bfb1af00084441f6",
  "5cb876b8bfb1af00084441f7",
  "5cb876b8bfb1af00084441f9",
  "5cb876b8bfb1af00084441fc",
  "5cb876c4bfb1af00084441fd",
  "5cb876c4bfb1af00084441fe",
  "5cb876c4bfb1af00084441ff",
  "5cb876c4bfb1af0008444200",
  "5cb876c4bfb1af0008444201",
  "5cb876c4bfb1af0008444203",
  "5cb876c4bfb1af0008444204",
  "5cb876c4bfb1af0008444205",
  "5cb876c4bfb1af0008444206",
  "5cb876cdbfb1af0008444207",
  "5cb876cdbfb1af0008444208",
  "5cb876cdbfb1af0008444209",
  "5cb876cdbfb1af000844420a",
  "5cb876cdbfb1af000844420b",
  "5cb876cdbfb1af000844420d",
  "5cb876cdbfb1af000844420f",
  "5d2f32f2e9fb9a0008be8de3",
  "5d2f32f2e9fb9a0008be8de6",
  "5d2f32f2e9fb9a0008be8dec",
  "5d2f32f2e9fb9a0008be8ded",
  "5d2f32f2e9fb9a0008be8df3",
  "5d2f32f2e9fb9a0008be8dfc",
  "62b48114a75e6e0008d0e023",
  "62b48115a75e6e0008d0e02a",
  "62b48117a75e6e0008d0e02d",
  "62b48118a75e6e0008d0e030",
  "62b48119a75e6e0008d0e034",
  "62b4811aa75e6e0008d0e03c",
  "62cf24d928b4b3000813a87e",
  "62cf24da28b4b3000813a881",
  "62cf24dc28b4b3000813a885",
  "62cf24dd28b4b3000813a88d",
  "62cf24df28b4b3000813a897",
  "62cf24e028b4b3000813a89f",
  "62cf24e228b4b3000813a8a2",
  "62cf24e328b4b3000813a8a5",
"5d6843a0eeafa7000652c04b",
  "5d6843a0eeafa7000652c04c",
  "5d6843a0eeafa7000652c04d",
  "5d6843a0eeafa7000652c04e",
  "5d6843a0eeafa7000652c04f",
  "5d6843a0eeafa7000652c050",
  "5d6843a0eeafa7000652c051",
  "5d6843a0eeafa7000652c053",
  "5d72befd5d23ee000666a713",
  "5d72befd5d23ee000666a714",
  "5d72befd5d23ee000666a715",
  "5d72befd5d23ee000666a719",
  "5d72befd5d23ee000666a71a",
  "5d72befd5d23ee000666a720",
  "5d72befd5d23ee000666a722",
  "5d72befd5d23ee000666a723",
  "5eea5670f3bd2f0006ab3e4e",
  "5eea5670f3bd2f0006ab3e56",
  "5eea5670f3bd2f0006ab3e59",
  "5eea5670f3bd2f0006ab3e5a",
  "5eea5670f3bd2f0006ab3e5d",
  "5eea5670f3bd2f0006ab3e62",
  "5eea5670f3bd2f0006ab3e66",
  "5eea5670f3bd2f0006ab3e67",
  "5eea5670f3bd2f0006ab3e6e",
  "5eea5670f3bd2f0006ab3e71",
  "5eea5670f3bd2f0006ab3e73",
  "5eea5670f3bd2f0006ab3e78",
  "5eea5670f3bd2f0006ab3e79",
  "5eea5670f3bd2f0006ab3e7d",
  "5eea5670f3bd2f0006ab3e7f",
"5e6f83b10903d60007e68bc7",
  "5e6f83b10903d60007e68bc8",
  "5e6f83b10903d60007e68bc9",
  "5e6f83b10903d60007e68bca",
  "5e6f83b10903d60007e68bcb",
  "5e6f83b10903d60007e68bce",
  "5e6f83b10903d60007e68bd0",
  "5e7bac8cfc829300068cf080",
  "5e7bac8cfc829300068cf081",
  "5e7bac8cfc829300068cf083",
  "5e7bac8cfc829300068cf084",
  "5e7bac8cfc829300068cf085",
  "5e7bac8cfc829300068cf08b",
  "5e7bac8cfc829300068cf08c",
  "5e7bac8cfc829300068cf08d",
  "5e7bac8cfc829300068cf08e",
  "5e7bac8cfc829300068cf08f",
  "5e7bac8cfc829300068cf090",
  "5e7bac8cfc829300068cf092",
  "5eb2ba34e1c4110006d699e3",
  "5eb2ba34e1c4110006d699e6",
  "5eb2ba34e1c4110006d699e7",
  "5eb2ba34e1c4110006d699e9",
  "5eb2ba34e1c4110006d699eb",
  "5eb2ba34e1c4110006d699ef",
  "5eb2ba34e1c4110006d699f1",
  "5eb2ba34e1c4110006d699f2",
  "5eb2ba34e1c4110006d699f3",
  "5eb2ba34e1c4110006d699f4",
  "5eb2ba34e1c4110006d699f7",
  "5eb2ba34e1c4110006d699f8",
  "5eb2ba34e1c4110006d699f9",
  "5eb2ba34e1c4110006d699fc",
  "5ee22a94ce2d30000685f44a",
  "5ee22a94ce2d30000685f44b",
  "5ee22a94ce2d30000685f44d",
  "5ee22a94ce2d30000685f456",
  "5ee22a94ce2d30000685f457",
  "62d854c56285890007dd145d",
  "62d854ca6285890007dd1460",
  "62d854cf6285890007dd1465",
  "62d854d46285890007dd146a",
  "62d854da6285890007dd146f",
  "62d854e96285890007dd1482",
  "62d854ee6285890007dd1493",
  "62d854f46285890007dd14a5",
  "62d854fa6285890007dd14bf",
  "62d8550d6285890007dd14db",
  "62d8552d6285890007dd1511",
  "62d855326285890007dd1523",
  "62d855376285890007dd152a",
  "62d8553d6285890007dd1538",
  "62d855426285890007dd1543",
  "62d855476285890007dd1548",
  "62d855506285890007dd155a",
  "62d855556285890007dd1573",
  "62d8555b6285890007dd157e",
  "62d855616285890007dd1586",
  "62d8556b6285890007dd1596",
  "62d855736285890007dd15aa",
  "62d855786285890007dd15b4",
  "62d8557f6285890007dd15bc",
  "62d855856285890007dd15cb",
  "62d868966285890007dd3124",
  "62d8689a6285890007dd312e"]

  // - UPLOAD
  selectedFile: Array<any> = [];
  uploadColumns: Array<PoTableColumn> = [];
  uploadActions: Array<PoTableAction> = [
    { action: this.downloadFile.bind(this), label: 'Download' },
    { action: this.deleteFile.bind(this), label: 'Delete' }
  ];

  sendUpload: PoModalAction = {
    action: () => {
      this.sendFiles();
    },
    label: 'Enviar'
  };

  cancelUpload: PoModalAction = {
    action: () => {
      this.loading = false;
      this.getFiles();
      this.uploadModal.close();
    },
    label: 'Cancelar'
  };


  //- CRIAÇÃO DE DATABASE

  dbCreate: PoModalAction = {
    action: () => {
      if (!this.validForm()) {
        this.notificationServiceHM.showToast("Preencha todos os campos!", 'warning')
      } else {
        if( !this._passwordValid ) {
          this.createDatabase();
          this.criaDBModal.close();
        } else {
          this.notificationServiceHM.showToast("Senha inválida! Só são permitidos @ % ? . e , como caracteres especiais", 'error')
        }
      }
    },
    label: 'Criar',
  };

  cancelCreate: PoModalAction = {
    action: () => {
      this.criaDBModal.close();
    },
    label: 'Cancelar'
  };

  receitas: Array<any> = []
  receitasBackups: Array<any> = []
  receitasFiltered: Array<PoSelectOptionGroup|PoSelectOption> = [];
  receitaID: string = '';
  recEscolhida: any = undefined;
  disableRecipe: boolean = true
  software: Array<any> = [];
  softwareSelected: string = '';
  expiraNew: number = 0;
  expiraOptions: Array<any> = [
    { label: '20 dias', value: 20 },
    { label: '40 dias', value: 40 }
  ];
  disableRestore: boolean = true;

  // - DELETE DATABASE

  toDelete: Database;
  canDelete: string = "NO";
  okDelete: PoModalAction = {
    action: () => {
      if (this.canDelete === "YES") {
        this.deleteDatabase();
        this.deleteDBModal.close();
      } else {
        this.notificationServiceHM.showToast("Confirme se realmente deseja excluir o Database", 'warning')
      }
    },
    label: 'Excluir'
  };

  cancelDelete: PoModalAction = {
    action: () => {
      this.deleteDBModal.close();
    },
    label: 'Cancelar'
  };

  // - SHARE - //
  databseIdToShare = '';
  usersSelected: Array<any> = [];
  usersInArea: Array<any> = [];

  getUpdateSharedData(event: { usersSelected: Array<any>}){
    this.usersSelected = event.usersSelected;
  }

  okShare: PoModalAction = {
    action: () => {
        this.shareDatabase();
        this.shareModal.close();
    },
    label: 'Compartilhar'
  };

  cancelShare: PoModalAction = {
    action: () => {
      this.loading = false;
      this.shareModal.close();
    },
    label: "Cancelar"
  };

  // - CHANGE PASSWORD - //
  passwordNew: string = '';
  changePasswordDatabaseID: string = '';

  okChangePassword: PoModalAction = {
    action: () => {
        this.changePasswordDatabase();
        this.loading = false;
        this.changePasswordModal.close();
    },
    label: 'Trocar'
  };

  cancelChangePassword: PoModalAction = {
    action: () => {
      this.loading = false;
      this.changePasswordModal.close();
    },
    label: "Cancelar"
  };

  // - EDIÇÃO E OUTRAS MUDANÇAS

  modificaDatabase: PoModalAction = {
    action: () => {
      setTimeout(() => {
        this.modificaDB();
        this.renameDBModal.close();
      }, 500)

    },
    label: 'Alterar'
  };

  toRestore: Database;

  okRestore: PoModalAction = {
    action: () => {
        this.restoreDatabase();
        this.loading = false;
        this.restoreModal.close();
    },
    label: 'Restaurar'
  };

  cancelRestore: PoModalAction = {
    action: () => {
      this.loading = false;
      this.restoreModal.close();
    },
    label: "Cancelar"
  };

  history: Array<any> = [ ];
  titleDetailsModal: string = '';
  columnsDetails = [
    { property: 'actionType', label: 'Açao', type: 'string' },
    { property: 'username', label: 'Username', type: 'string' },
    { property: 'description', label: 'Descrição', type: 'string' },
    { property: 'createdAt', label: 'Created at', type: 'string' },
    { property: 'updatedAt', label: 'Updated at', type: 'string' }
  ];

  okHistory: PoModalAction = {
    action: () => {
        this.loading = false;
        this.historyModal.close();
    },
    label: 'Cancelar'
  };

  // - OPÇÕES DE CADA RESOURCE - //
  itemListOptions: Array<PoListViewAction> = [
    { label: 'Prorrogar / Renomear', action: this.alterarDB.bind(this), disabled: this.cannotExtendExpiration.bind(this)},
    { label: 'Reiniciar DbAccess', action: this.restartDbAccess.bind(this), disabled: this.cannotRestart.bind(this)},
    { label: 'Histórico', action: this.databaseHistory.bind(this) },
    { label: 'Realizar Backup', action: this.backupDatabase.bind(this), disabled: this.cannotBackup.bind(this) },
    { label: 'Restaurar Backup', action: this.backupRestore.bind(this), disabled: this.cannotBackup.bind(this) },
    { label: 'Trocar Senha', action: this.changePassword.bind(this), disabled: this.cannotChangePassword.bind(this) },
    { label: 'Compartilhar', action: this.shareResource.bind(this), disabled: this.cannotShare.bind(this) },
    { label: 'Excluir', action: this.shouldDelete.bind(this), disabled: this.cannotDelete.bind(this) }
  ];

  constructor(
    @Inject(LOCALE_ID)
    public locale: string,
    public mainService: MainService,
    private databaseService: DatabaseService,
    public poNotificationService: PoNotificationService,
    public notificationServiceHM: NotificationServiceHM,
    private readonly tracker: MatomoTracker) { }

  ngOnInit(): void {
    this.tracker.trackPageView('Database');
    this.getDatabases();
    this.getFiles();
    this.getSoftwares();
    this.getReceitas();
  }

  updateScreenData(){
    this.getDatabases();
    this.getFiles();
  }

  getDatabases(){
    this.loading = true;
    this.databases = [];
    this.databasesView = [];
    this.databaseService.getDatabases()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
              next: ((response) => {
                response.forEach(element => {
                  const databaseTemp = new Database();
                  databaseTemp.id = element.id;
                  databaseTemp.name = element.name;
                  databaseTemp.expirationDate = element.expirationDate.split('T', 1);

                  let remainingTime = element.expirationDate;
                  remainingTime = new Date(remainingTime).valueOf() - new Date().valueOf();
                  databaseTemp.remainingTime = Math.floor(remainingTime/ (1000 * 3600 * 24));

                  databaseTemp.status = element.status || element.deployStats;
                  switch ( databaseTemp.status.toLowerCase() ) {
                    case 'failed':
                      databaseTemp.statusType = PoTagType.Info;
                      databaseTemp.nStatus = 1;
                      databaseTemp.active = false;
                      break;

                    case 'expiring':
                      databaseTemp.statusType = PoTagType.Warning;
                      databaseTemp.nStatus = 2;
                      databaseTemp.active = true;
                      break;

                    case 'expired':
                      databaseTemp.statusType = PoTagType.Danger;
                      databaseTemp.nStatus = 3;
                      databaseTemp.active = false;
                      break;

                    case 'creating':
                      databaseTemp.statusType = PoTagType.Success;
                      databaseTemp.nStatus = 4;
                      databaseTemp.active = true;
                      break;

                    case 'deploying':
                      databaseTemp.statusType = PoTagType.Success;
                      databaseTemp.nStatus = 4;
                      databaseTemp.active = true;
                      break;

                    case 'active':
                      databaseTemp.statusType = PoTagType.Success;
                      databaseTemp.nStatus = 5;
                      databaseTemp.active = true;
                      break;

                    case 'deployed':
                      databaseTemp.statusType = PoTagType.Success;
                      databaseTemp.nStatus = 5;
                      databaseTemp.active = true;
                      break;

                    case 'deleting':
                      databaseTemp.statusType = PoTagType.Info;
                      databaseTemp.nStatus = 6;
                      databaseTemp.active = false;
                      break;

                    case 'deleted':
                      databaseTemp.statusType = PoTagType.Info;
                      databaseTemp.nStatus = 6;
                      databaseTemp.active = false;
                      break;

                  };

                  databaseTemp.username = element.login || element.username;

                  if (element.metadata && element.kind === 'resource') {
                    databaseTemp.dbipporta = element.metadata.database;
                    databaseTemp.dbaccess = element.metadata.dbaccess;
                    databaseTemp.instanceType = element.metadata.type;

                  } else if (element.metadata && element.kind === 'database') {
                    databaseTemp.dbipporta = `${element.metadata.host}:${element.metadata.port}`;
                    databaseTemp.username = element.metadata.user;
                    databaseTemp.password = element.metadata.pass;
                    databaseTemp.instanceType = element.metadata.type;
                    databaseTemp.visualPassword = '*****';

                    if (element.metadata.artifacts) {
                      for (let item of element.metadata.artifacts) {
                        if (item.name.toLowerCase() === 'dbaccess') {
                          databaseTemp.dbaccess = `${item.ip}:${item.port}`;
                        }
                      }
                    }

                  }

                  if (databaseTemp.instanceType === 'PROTHEUS') {
                    databaseTemp.name += ' - PROTHEUSDB'
                  }

                  databaseTemp.softwareType = element.softwareType;

                  switch (element.softwareType) {
                    case 'MSSQL':
                      databaseTemp.alias = 'MSSQL';
                      break;

                    case 'POSTGRES':
                      databaseTemp.alias = 'POSTGRES';
                      break;

                    case 'ORACLE':
                      if (databaseTemp.instanceType === 'PROTHEUS') {
                        databaseTemp.alias = 'ORACLE';
                      } else {
                        databaseTemp.alias = element.login;
                      }
                      break;
                  }

                  databaseTemp.shared = element.shared;
                  databaseTemp.owner = element.username;

                  /// REGRA PARA MIGRAÇÃO R22
                  databaseTemp.spotId = element.spotId || "";
                  databaseTemp.kind = element.kind;

                  databaseTemp.createdAt = element.createdAt.split('T', 1);
                  databaseTemp.updatedAt = element.updatedAt.split('T', 1);

                  if ( this.activeOptions.includes(databaseTemp.status)){
                    this.databasesView = [...this.databasesView, databaseTemp];
                  }
                  this.databases = [...this.databases, databaseTemp];
                });
                this.loading = false;
              }),
              error: ((msgError) => {
                this.mainService.handleError(msgError);
                this.loading = false;
              })},
      );
  }

  getSoftwares(){
    this.loading = true;
    this.databaseService.getSoftware()
      .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: ((response) => {
            response.forEach(element => {
              const softwareTemp = new Software();
              softwareTemp.id = element.id;
              softwareTemp.category = element.category;
              softwareTemp.name = element.name;
              softwareTemp.softwareType = element.softwareType;
              softwareTemp.version = element.version;
              softwareTemp.label = element.name;
              softwareTemp.value = element.id;
              this.software = [...this.software, softwareTemp];
            });
              this.loading = false;

          }),
          error: ((msgError) => {
            this.mainService.handleError(msgError);
            this.loading = false;
          })
        });
  }

  getSoftwareByID(softwareID) {
    let filtered = this.software.filter(x => x.id == softwareID);
    if (filtered.length < 1) {
      this.loading = false;
      return 'NOT FOUND';
    }
    else {
      this.loading = false;
      return filtered[0];
    }
  }

  onChangeSoftware(element) {
    this.receitaID = '';

    const software = this.software.find(e => e.id === element);
    this.receitasFiltered = [
      {
        label: "Receitas",
        options: this.receitas.filter(x => x.softwareId == element || x.softwareType === software.softwareType)
      },
      { label: "Meus Backups",
        options: this.receitasBackups.filter(x => x.name.endsWith(`.${software.softwareType.toLowerCase()}.zip`))
      }
    ];

    if (this.receitasFiltered.length < 1) {
      this.receitasFiltered = [{ label: 'NOT FOUND', value: 'NOT FOUND'}];
      this.disableRecipe = true;
    } else {
      this.disableRecipe = false;
    }
  }

  getReceitas() {
    this.receitas = [];
    this.loading = true;
    this.databaseService.getRecipesByType('database')
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: ((response) => {
        response.forEach(element => {
          const receitasTemp = new Recipes();
          receitasTemp.id = element.id;
          if (element._id) receitasTemp.id = element._id;
          receitasTemp.name = element.name;
          receitasTemp.preset = element.preset;
          receitasTemp.softwareId = element.softwareId;
          receitasTemp.version = element.version;
          receitasTemp.presetVersion = element.presetVersion;
          receitasTemp.value = element.id;
          let version = element.presetVersion === '-' ? '' : ` - ${element.presetVersion}`;
          let label = element.kind === 'template' ? element.name : `${element.name} -  ${element.preset}${version}`;
          receitasTemp.label = label;
          receitasTemp.value = receitasTemp.id;

          receitasTemp.kind = element.kind;
          receitasTemp.language = element.language;
          receitasTemp.locale = element.locale;
          receitasTemp.release = element.release;
          receitasTemp.softwareType = element.softwareType;
          receitasTemp.url = element.url;
          this.receitas = [...this.receitas, receitasTemp];
        });

        this.receitas.sort((a, b) => {
          if (a['label'] < b['label']) {
            return this.ascendingDescending ? -1 : 1;
          }
          if (a['label'] > b['label']) {
            return this.ascendingDescending ? 1 : -1;
          }
          return 0;
        });

        this.loading = false;
      }),
      error: ((msgError) => {
        this.mainService.handleError(msgError);
        this.loading = false;
      })
    });
  }

  getFiles() {
    let uploadExtension = [];
    this.uploadItems = [];
    this.receitasBackups = [];
    this.canUpload = true;
    this.loading = true;
    this.databaseService.getFiles()
    .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: ((response) => {
            response.forEach(element => {
              const uploadTemp = new Files();

                uploadTemp.name = element.name;
                uploadExtension = element.name.split('.');
                uploadTemp.extension = uploadExtension[uploadExtension.length - 1];
                uploadTemp.lastModified = element.lastModified.split('T', 1);
                uploadTemp.size = (element.size / 1000);

                this.uploadItems = [...this.uploadItems, uploadTemp];
                if ( this.mainService.userToken.role === 'superuser' ) {
                  this.canUpload = true;
                } else {
                  this.canUpload = ( this.uploadItems.length >= 4 ) ? false : true;
                }

                // Adiciona no combo de templates / receitas os backups atuais para restore
                const receitasTemp = new Recipes();
                receitasTemp.id = element.name;
                receitasTemp.name = element.name;
                receitasTemp.value = element.name;
                receitasTemp.label = element.name;
                receitasTemp.kind = "backup";
                this.receitasBackups.push(receitasTemp);
            });

            this.receitasBackups.sort((a, b) => {
              if (a['label'] < b['label']) {
                return this.ascendingDescending ? -1 : 1;
              }
              if (a['label'] > b['label']) {
                return this.ascendingDescending ? 1 : -1;
              }
              return 0;
            });

            this.loading = false;
          }),
          error: ((msgError) => {
            this.mainService.handleError(msgError);
            this.loading = false;
          })
        });
  }

  toggleFilters() {

   this.filterToggle = !this.filterToggle;
  }

  filteredData(event: Array<any>){
    this.databasesView = event;
  }

  filterRecipeItem(id: string) {
    let filtered = this.receitas.filter(x => x.id == id);
    if (filtered.length < 1) filtered = this.receitasBackups.filter(x => x.id == id);
    if (filtered.length < 1) return 'NOT FOUND';
    else return filtered[0];
  }

  onChangeRecipe(recipe) {
    let recipeFull = this.filterRecipeItem(recipe);
    this.recEscolhida = recipeFull;
    let preset = recipeFull.preset;
    let softwareId = recipeFull.softwareId;

    // quando muda o template verifica se mostra password
    if (recipeFull.kind === "template" || recipeFull.kind === "backup") {
      this._password = "";
      this.showPassword = false;
      this._passwordValid = false;  //a logica esta inversa
    } else {
      this._password = "";
      this.showPassword = true;
      this._passwordValid = false;  //a logica esta inversa
    }

    if ( preset === 'restore' ) {
      this.disableRestore = false;
      this.loading = true;
      const softwareGot = this.getSoftwareByID(softwareId)

      this.filterBackups(recipeFull);

    } else {
      this.disableRestore = true;
      this.chosenBackup = '';
      this.loading = false;
    }
  }

  checkPassword(password) {
    this._passwordValid = /[^a-zA-Z0-9@%?.,]/gm.test(password);
  }

  selectRightExtension(softwareType) {
    switch (softwareType) {
      case 'MSSQL':
        return 'bak';

      case 'POSTGRES':
        return 'dump';

      case 'ORACLE':
        return 'gz'
    }
    return '';
  }

  filterBackups (filter: Database|Recipes) {
    this.backups = [];

    if (!filter.softwareType) {
      let fixRecipe : any = filter;
      let software = this.software.find(e => (e.id || e._id) === fixRecipe.softwareId);
      fixRecipe.softwareType = software.softwareType;
    }

    const extension = this.selectRightExtension(filter.softwareType);
    const softwareType = filter.softwareType.toLowerCase();

    this.backups = this.uploadItems.filter(upload => {
      const validExtension = upload.extension.toLowerCase() === extension.toLowerCase();
      const validName = upload.name.includes(`.${softwareType}.zip`);
      return validExtension || validName;
    }).map(upload => {
      return {label: upload.name, value: upload.name}
    });
  }

  validForm() {
    let validRestore: boolean = true;
    if (this.recEscolhida.preset === 'restore') {
      this.chosenBackup === '' ? validRestore = false : validRestore = true
    }
    return !!this.recEscolhida && !!this._name && validRestore;
  }

  generatePassword() {
      let password = "";
      const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      for (let i = 0; i < 8; i++)
      password += possible.charAt(Math.floor(Math.random() * possible.length));

      return password;
  }

  createDatabase() {
    let finaldate = 7;
    if (this.disableRestore) this.chosenBackup = '';

    this.loading = true;

    if (this.recEscolhida.kind === "recipe") {
      this.databaseService.createDatabase(this._name, finaldate, this.recEscolhida.id, this._password, this.chosenBackup).pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe({
        next: ((response) => {
          // this.poNotification.message = "Database " + name + " em processo de criação!"
          // this.poNotificationService.success(this.poNotification)
          this.notificationServiceHM.showToast("Database " + this._name + " em processo de criação!", 'success')
          this.loading = false;
        }),
        error: ((msgError) => {
          this.mainService.handleError(msgError);
          this.loading = false;
        })
       });
    } else if (this.recEscolhida.kind === "template") {
      this.databaseService.createDatabase2(this._name, this.recEscolhida.id, undefined).pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe({
        next: ((response) => {
          // this.poNotification.message = "Database " + name + " em processo de criação!"
          // this.poNotificationService.success(this.poNotification)
          this.notificationServiceHM.showToast("Database " + this._name + " em processo de criação!", 'success')
          this.loading = false;
        }),
        error: ((msgError) => {
          this.mainService.handleError(msgError);
          this.loading = false;
        })
       });
    } else if (this.recEscolhida.kind === "template") {
      this.databaseService.createDatabase2(this._name, this.recEscolhida.id, this.recEscolhida.id).pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe({
        next: ((response) => {
          // this.poNotification.message = "Database " + name + " em processo de criação!"
          // this.poNotificationService.success(this.poNotification)
          this.notificationServiceHM.showToast("Database " + this._name + " em processo de criação!", 'success')
          this.loading = false;
        }),
        error: ((msgError) => {
          this.mainService.handleError(msgError);
          this.loading = false;
        })
       });
    } else if (this.recEscolhida.kind === "backup") {
      this.databaseService.createDatabase2(this._name, this.recEscolhida.id, this.recEscolhida.id).pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe({
        next: ((response) => {
          // this.poNotification.message = "Database " + name + " em processo de criação!"
          // this.poNotificationService.success(this.poNotification)
          this.notificationServiceHM.showToast("Database " + this._name + " em processo de criação!", 'success')
          this.loading = false;
        }),
        error: ((msgError) => {
          this.mainService.handleError(msgError);
          this.loading = false;
        })
       });
    }

    this.criaDBModal.close();
    this.getDatabases();
  }


  cannotDelete(instanciaSelect): boolean {

    if (instanciaSelect.owner !== this.mainService.userToken.username) {
      return true;
    }
    if (instanciaSelect.instanceType === 'PROTHEUS') {
      return true;
    }

    return this.cannotModifyOptions.includes(instanciaSelect['status'].toLowerCase());
  }

  shouldDelete(databaseSelect) {
    this.toDelete = databaseSelect;
    this.deleteDBModal.open();
  }

  deleteDatabase() {
    this.loading = true;

    if (this.toDelete.kind === 'database') {
      this.databaseService.deleteDatabase2(this.toDelete.id)
      .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: ((response) => {
            // this.poNotification.message = "Database " + response.name + " em processo de deleção!";
            // this.poNotificationService.success(this.poNotification);
            this.notificationServiceHM.showToast("Database " + response.name + " em processo de deleção!", 'success')
            this.getDatabases();
          }),
          error: ((msgError) => {
            this.mainService.handleError(msgError);
            this.loading = false;
          })
        });
    } else {
      this.databaseService.deleteDatabase(this.toDelete['id'])
      .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: ((response) => {
            // this.poNotification.message = "Database " + response.name + " em processo de deleção!";
            // this.poNotificationService.success(this.poNotification);
            this.notificationServiceHM.showToast("Database " + response.name + " em processo de deleção!", 'success')
            this.getDatabases();
          }),
          error: ((msgError) => {
            this.mainService.handleError(msgError);
            this.loading = false;
          })
        });
    }
    this.deleteDBModal.close();
  }

  alterarDB(databaseSelect) {
    this._name = databaseSelect['name'];
    this._id = databaseSelect['id'];
    this.expiraNew = 0;
    this.renameDBModal.open();
  }

  getUpdateData(event: { _name: string, _expirationDays: number}){
    this._name = event._name;
    this.expiraNew = event._expirationDays
  }

  modificaDB() {
    this.loading = true;
    this.databaseService.alterarDatabase(this._id, this._name, this.expiraNew)
    .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: ((response) => {
          this.getDatabases();
        }),
        error: ((msgError) => {
          this.mainService.handleError(msgError);
          this.loading = false;
        })
      });
  }

  cannotExtendExpiration(databaseSelect): boolean {
    if ( this.mainService.userToken.role === 'team')  return true;
    if (databaseSelect.instanceType === "PROTHEUS") return true;

    if ( this.___SPOTIDTOBLOCK.includes(databaseSelect['spotId']) ) { // REGRA EXCEPCIONAL PARA MIGRAÇÃO DO R22
      console.log('Bloqueio por conta da migração do R22')
      return true;
    }

    return !(this.activeOptions.includes(databaseSelect['status'].toLowerCase()));
  }

  restartDbAccess(databaseSelected) {
    this.loading = true;

    if (databaseSelected.kind === 'database') {
      this.databaseService.restartDbAccess2(databaseSelected.id)
      .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: ((response) => {
            // this.poNotification.message = "Dbaccess da base " + databaseSelected['name'] + " está sendo reiniciado"
            // this.poNotificationService.success(this.poNotification)
            this.notificationServiceHM.showToast("Dbaccess da base " + databaseSelected['name'] + " está sendo reiniciado", 'success')
            this.loading = false;
          }),
          error: ((msgError) => {
            this.mainService.handleError(msgError);
            this.loading = false;
          })
      });
    } else {
      this.databaseService.restartDbAccess(databaseSelected['id'])
      .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: ((response) => {
            // this.poNotification.message = "Dbaccess da base " + databaseSelected['name'] + " está sendo reiniciado"
            // this.poNotificationService.success(this.poNotification)
            this.notificationServiceHM.showToast("Dbaccess da base " + databaseSelected['name'] + " está sendo reiniciado", 'success')
            this.loading = false;
          }),
          error: ((msgError) => {
            this.mainService.handleError(msgError);
            this.loading = false;
          })
      });
    }
  }

  cannotBackup(instanciaSelect): boolean {
    if ( instanciaSelect.owner !== this.mainService.userToken.username) {
      return true;
    }
    if ( this.mainService.userToken.role === 'team') {
        return true;
    }

      return this.cannotModifyOptions.includes(instanciaSelect['status'].toLowerCase());
  }

  backupDatabase(databaseSelected) {
    this.loading = true;

    if (databaseSelected.kind === 'database') {
      this.databaseService.backupDatabase2(databaseSelected.id)
      .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: ((response) => {
            // this.poNotification.message = "Backup do database " + databaseSelected.name + " iniciado com sucesso!"
            // this.poNotificationService.success(this.poNotification)
            this.notificationServiceHM.showToast("Backup do database " + databaseSelected.name + " iniciado com sucesso!", 'success');
            this.loading = false;
          }),
          error: ((msgError) => {
            this.mainService.handleError(msgError);
            this.loading = false;
          })
        });
    } else {
      this.databaseService.backupDatabase(databaseSelected['id'])
      .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: ((response) => {
            // this.poNotification.message = "Backup do database " + databaseSelected['name'] + " iniciado com sucesso!"
            // this.poNotificationService.success(this.poNotification)
            this.notificationServiceHM.showToast("Backup do database " + databaseSelected['name'] + " iniciado com sucesso!", 'success')
            this.loading = false;
          }),
          error: ((msgError) => {
            this.mainService.handleError(msgError);
            this.loading = false;
          })
        });
    }
  }

  backupRestore(databaseSelected) {
    this.toRestore = databaseSelected;
    this.filterBackups(databaseSelected);
    this.chosenBackup = '';
    this.restoreModal.open();
  }

  restoreDatabase() {
    this.loading = true;

    if (this.toRestore.kind === "database") {
      const databaseId = this.toRestore.id;
      const filename = this.chosenBackup;

      this.databaseService.restoreDatabase2(databaseId, filename)
      .pipe(takeUntil(this.unsubscribe$))
        .subscribe(res => {
          // this.poNotification.message = "Restauração iniciada com sucesso!"
          // this.poNotificationService.success(this.poNotification);
          this.notificationServiceHM.showToast("Restauração iniciada com sucesso!", 'success');
          this.getDatabases();
        });
    } else {
      this.databaseService.restoreDatabase(this.toRestore['id'], this.chosenBackup)
      .pipe(takeUntil(this.unsubscribe$))
        .subscribe(res => {
          // this.poNotification.message = "Restauração iniciada com sucesso!"
          // this.poNotificationService.success(this.poNotification);
          this.notificationServiceHM.showToast("Restauração iniciada com sucesso!", 'success');
          this.getDatabases();
        });
    }
    this.restoreModal.close();
  }

  cannotShare(instanciaSelect): boolean {
    if ( instanciaSelect.owner !== this.mainService.userToken.username) {
      return true;
    }
      return false;
  }

  shareResource(instanciaSelect) {
    this.usersInArea = [];
    this.databseIdToShare = instanciaSelect['id'];
    this.usersSelected = instanciaSelect['shared'];

    let usersUpdated = this.usersSelected.map(user => {
      return {label: user, value: user}}
    );
    this.usersInArea = usersUpdated;
    this.shareModal.open();
  }

  shareDatabase() {
    this.loading = true;
    this.databaseService.shareDatabase(this.databseIdToShare, this.usersSelected)
      .subscribe(res => {
        this.notificationServiceHM.showToast('Database ' + res.name + ' compartilhada com sucesso', 'success')
        this.loading = false;
      });
      this.getDatabases();
  }

  cannotChangePassword(databaseSelected): boolean {
    if ( databaseSelected.owner !== this.mainService.userToken.username) return true;
    if (databaseSelected.instanceType === "PROTHEUS") return true;

    return this.cannotModifyOptions.includes(databaseSelected['status'].toLowerCase());
  }

  cannotRestart(databaseSelected): boolean {
    return databaseSelected.instanceType === "PROTHEUS";
  }

  changePassword(databaseSelected) {
    this.changePasswordDatabaseID = databaseSelected['id'];
    this.changePasswordModal.open();
  }

  changePasswordDatabase() {
    this.loading = true;
    const database = this.databases.filter(e => e.id === this.changePasswordDatabaseID);

    if (database[0]['kind'] === 'resource') {
      this.databaseService.changePasswordDatabase(this.changePasswordDatabaseID, this.passwordNew)
      .subscribe({
        next: (res => {
          this.getDatabases();
          this.loading = false;
        }),
        error: ((msgError) => {
          this.mainService.handleError(msgError);
          this.loading = false;
        })
      });
    } else if (database[0]['kind'] === 'database') {
       this.databaseService.changePasswordDatabase2(this.changePasswordDatabaseID, this.passwordNew)
       .subscribe({
        next: (res => {
          this.getDatabases();
          this.loading = false;
        }),
        error: ((msgError) => {
          this.mainService.handleError(msgError);
          this.loading = false;
        })
      });
   }

    this.changePasswordModal.close();
  }

  databaseHistory(databaseSelected) {
    this.history = [ ];
    this.loading = true;
    this.databaseService.getActionsbyResource(databaseSelected['id'])
        .subscribe({
          next: ((response) => {
            response.forEach(element => {

              const historyTemp = new Actions();
              historyTemp.Id = element.id;
              historyTemp.resourceId = element.resourceId;
              historyTemp.recipeId = element.recipeId;
              historyTemp.actionType = element.actionType;
              historyTemp.username = element.username;
              historyTemp.description = element.description;
              historyTemp.createdAt = element.createdAt.replace('T', ' - ').split('.', 1);
              historyTemp.updatedAt = element.updatedAt.replace('T', ' - ').split('.', 1);

              this.history = [...this.history, historyTemp];
            });
            this.loading = false;
          }),
          error: ((msgError) => {
            this.mainService.handleError(msgError);
            this.loading = false;
          })
        });
      this.titleDetailsModal = databaseSelected['name'];
      this.historyModal.open();
  }

  resetValuesUpload() {
    this.selectedFile = [];
    if ( this.mainService.userToken.role === 'superuser') {
      this.canUpload = true;
    }
  }

  resetValuesCriaDb() {
    this.softwareSelected = '';
    this.receitaID = '';
    this.chosenBackup = '';
    this._password = '';
  }

  resumeUploadError() {

    this.mainService.handleError(this.selectedFile[0]);
    // this.poNotificationService.error('Falha ao realizar o upload!');
    this.notificationServiceHM.showToast('Falha ao realizar o upload!', 'error')
    this.getFiles();
    this.loading = false;
  }

  resumeUploadSuccess() {
    this.loading = false;
    // this.poNotificationService.success('Upload realizado com sucesso!');
    this.notificationServiceHM.showToast('Upload realizado com sucesso!', 'success');
    this.getFiles();
    this.uploadModal.close();
  }

  sendFiles() {

    this.loading = true;
    // this.poNotificationService.success('Upload iniciado...');
    this.notificationServiceHM.showToast('Upload iniciado...', 'success')
    this.databaseService.uploadImage(this.selectedFile[0].rawFile, this.selectedFile[0].name ).subscribe(
            (res) => {
              this.resumeUploadSuccess();
            },
            (err) => {
              this.resumeUploadError();
            });
  }

  deleteFile(fileSelected) {
    this.loading = true;
    this.databaseService.deleteFile(fileSelected['name'])
      .subscribe(res => {
        this.getFiles();
        this.loading = false;
      });
      this.uploadModal.close();
      this.uploadModal.open();
  }

  downloadFile(fileSelected) {
    this.loading = true;
    this.databaseService.downloadFile(fileSelected['name'])
      .subscribe(res => {

        saveAs(res.url, fileSelected['name']);

        this.getFiles();
        this.loading = false;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
