import { Component, Input, OnChanges } from '@angular/core';
import { Protheus } from 'src/app/classes/Protheus';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnChanges {

  _id: string = '';
  _deployStats: string = '';
  _deployStatsData: string = '';
  _deployStatsData_message: string = '';
  _sgdbData: string = '';
  _sgdbData_id: string = '';
  _sgdbData_type: string = '';
  _sgdbData_username: string = '';
  _sgdbData_password: string = '';
  _username: string = '';
  _artifacts: any[] = [];
  _webappURL: string = '';
  _ini: string = '';
  _loginString: string = '';

  @Input() protheusSelected: Object = {}
  @Input() bases: Array<Object> = []

  ngOnChanges(): void {
    if(Object.keys(this.protheusSelected).length !== 0 && this.protheusSelected !== undefined ) {
      this._id = this.protheusSelected['id'];
      this._deployStats = this.protheusSelected['deployStats'];
      this._deployStatsData = this.protheusSelected['deployStatsData'];
      this._deployStatsData_message = this.protheusSelected['deployStatsData']?.message; //conferir porque não aparece
      this._sgdbData = this.protheusSelected['sgdbData'];
      this._sgdbData_id =  this.protheusSelected['sgdbData']?.databaseId;
      this._sgdbData_type = this.protheusSelected['sgdbData'].type;
      this._sgdbData_username = this.protheusSelected['sgdbData']?.username;
      this._sgdbData_password = this.protheusSelected['sgdbData']?.password;
      this._artifacts = this.protheusSelected['artifacts'];
      this._username = this.protheusSelected['username'];
      const multiprotocolport = this.protheusSelected['specs'].appserver[0].modifiedIni.drivers.multiprotocolport
      this._artifacts.forEach(element => {
        if (multiprotocolport !== undefined
          && multiprotocolport === '1'
          && this.protheusSelected['specs'].appserver[0].modifiedIni.general['app_environment'] !== ''
          && element.name === 'smartclient') {
            this._webappURL = 'http://' + element['ip'] + ':' + element['port'] + '/webapp';
        } else if (element.name === 'webapp') {
            this._webappURL = 'http://' + element['ip'] + ':' + element['port'];
        };
      });

      if (this.protheusSelected['specs'].appserver[0]['spec'] !== undefined) {
        if (this.protheusSelected['specs'].appserver[0].spec.spec.ini !== undefined && this.protheusSelected['specs'].appserver[0].spec.spec.ini !== '') {
          this._ini = JSON.stringify(this.protheusSelected['specs'].appserver[0].spec.spec.ini, null, 2);
        }
      }
      if (this.protheusSelected['specs'].appserver[0].modifiedIni !== undefined && this.protheusSelected['specs'].appserver[0].modifiedIni !== '') {
        this._ini = JSON.stringify(this.protheusSelected['specs'].appserver[0].modifiedIni, null, 2);
      }

    }
    if (this.bases !== undefined && this.bases['tipo'] !== undefined) {
      if (this.bases['tipo'] === 'congelada' ) this._loginString = 'Admin | 1234'
      if (this.bases['tipo'] === 'inicializada' ) this._loginString = 'Admin | (sem senha)'
    }

  }
}
