import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PoModule } from '@po-ui/ng-components';

@Component({
  selector: 'app-filter-screen',
  templateUrl: './filter-screen.component.html',
  styleUrls: ['./filter-screen.component.css'],
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    PoModule
  ]
})
export class FilterScreenComponent {

  @Input() origin: string = ''
  @Input() originalData : Array<any> = [];
  @Input() activeOptions: Array<string> = [];
  @Input() deleteOptions: Array<string> = [];
  @Input() filterOptionsSelect: Array<any> = []
  @Output() filteredDataEmitter = new EventEmitter<any>();

  filterInstancesModel: Object = {
    active: true,
    deleted: false,
    expired: false,
    failed: false
  }

  filterOptions: Array<any> = [
    {value: 'active', label: 'Ativos'}, 
    { value: 'deleted', label: 'Histórico' }, 
    { value: 'failed', label: 'Falha' }
  ];

  filterSelected: string = '';
  ascendingDescending: boolean = true;
  filteredData: Array<any> = []

  ngOnChanges(): void {
  }

  onChangeFilterSelect(filter) {
    this.filteredData.sort((a, b) => {
      if (a[filter] < b[filter]) {
        return this.ascendingDescending ? -1 : 1;
      }
      if (a[filter] > b[filter]) {
        return this.ascendingDescending ? 1 : -1;
      }
      return 0;
    }); 
    this.onChange()
  }

  invertFilterOrder(){
    this.ascendingDescending = !this.ascendingDescending;
    this.onChangeFilterSelect(this.filterSelected);
  }

  filterInstances(filterObject) {
    let filtered = Object.keys(filterObject).filter(key => filterObject[key] === true);

    if (filtered.includes('active')) {
      this.activeOptions.forEach( element => {
        filtered = [ ...filtered, element];
      })
    }
    
    if (filtered.includes('deleted')) {
      this.deleteOptions.forEach( element => {
        filtered = [...filtered, element]
      })
    }

    this.filteredData = [];
    switch(this.origin) {
      case 'database':
        this.filteredData = this.originalData.filter(x =>filtered.includes(x.status));
        break;
      case 'protheus':
        this.filteredData = this.originalData.filter(x =>filtered.includes(x.deployStats));
        break;
    }
    
    this.onChangeFilterSelect(this.filterSelected);
  }

  onChange() {
    this.filteredDataEmitter.emit(this.filteredData);
}
}
