import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ProtheusComponent } from './protheus.component';
import { PoModule } from '@po-ui/ng-components';
import { ProtheusService } from './protheus.service';
import { MainService } from 'src/app/app.service';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { PoTemplatesModule } from '@po-ui/ng-templates';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RenameExtendComponent } from "../shared/rename-extend/rename-extend.component";
import { ShareResourceComponent } from "../shared/share-resource/share-resource.component";
import { DetailsComponent } from './components/details/details.component';
import { FilterScreenComponent } from '../shared/filter-screen/filter-screen/filter-screen.component';
import { NotificationItemComponent } from '../notification-item/notification-item.component';

@NgModule({
  declarations: [
    ProtheusComponent,
    DetailsComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    AppRoutingModule,
    PoModule,
    PoTemplatesModule,
    NotificationItemComponent,
    BrowserAnimationsModule,
    RenameExtendComponent,
    ShareResourceComponent,
    FilterScreenComponent
],
  providers: [
    MainService,
    ProtheusService
  ]
})
export class ProtheusModule { }
