import { Component, Input } from '@angular/core';
import { Database } from 'src/app/classes/Database';

@Component({
  selector: 'app-database-view',
  templateUrl: './database-view.component.html',
  styleUrls: ['./database-view.component.css']
})
export class DatabaseViewComponent {
  @Input() databaseItem: Database;

  displayPassword(databaseItem): void {
    if (databaseItem.password === databaseItem.visualPassword) return;

    databaseItem.visualPassword = databaseItem.password;

    setTimeout(() => {
      databaseItem.visualPassword = '*****';
    }, 3000);
  }
}
