import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MATOMO_CONFIGURATION, MatomoConfiguration, MatomoTracker } from '@ngx-matomo/tracker';
import { PoMenuItem, PoModalAction, PoModalComponent, PoNotificationService, PoToolbarAction } from '@po-ui/ng-components';
import { MainService } from '../app.service';
import { Areas } from '../classes/Areas';
import { MenuRoute } from '../classes/Menu';
import { Token } from '../classes/Token';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  @ViewChild('getPATAToken', {static: false}) getPATAToken: PoModalComponent;
  @ViewChild('getCompileKey', {static: false}) getCompileKey: PoModalComponent;

  actualArea: string = this.mainservice.userToken.area;

  // PATA TOKEN
  tokenExists: boolean = false;
  tokenColumns: Array<any> = [];
  tokens: Array<any> = [];
  tokenUser: string = '';
  tokenData: string = '';
  tokenDate: string = '';
  tUsername: string = '';
  tPassword: string = '';
  patatoken: string = '';
  compileKey = {
    "payload": {
      "iss": "",
      "sub": "",
      "auth": "",
      "exp": ""
    },
    "token": ""
  };

  profileActions: Array<PoToolbarAction> = [
    { icon: 'po-icon-settings', label: 'Gerar token de compilação', type: 'default', separator: true, action: item => this.openCompileKey()},
    { icon: 'po-icon-exit', label: 'Sair', type: 'danger', separator: true, action: item => this.mainservice.logout() }
  ];

  menu: Array<any> = []

  constructor(
    private router: Router,
    public mainservice: MainService,
    public poNotification: PoNotificationService,
    public cd: ChangeDetectorRef,
    private readonly tracker: MatomoTracker,
    @Inject(MATOMO_CONFIGURATION) private readonly config: MatomoConfiguration) { 
      config.siteId === '1',
      config.trackerUrl === '//10.171.80.34:9603/';
    }

  ngOnInit(): void {
    this.tracker.trackPageView('Menu');
    this.getMenu();
  }

  ngAfterViewInit() {
    // if (this.mainservice.userToken.area === '') {
    //   this.poNotification.warning("Você deve selecionar uma área de trabalho para continuar o acesso!");
    //   this.openSelectArea();
    // } else {
    //   this.router.navigate(['menu/home']);
    // }
    this.router.navigate(['menu/home']);
    this.cd.detectChanges();
  }


  getMenu() {
    this.menu = [];

    this.mainservice.getMenu()
      .subscribe(res => {
        res.forEach(element => {
            const menuTemp = new MenuRoute();
            menuTemp.id = element.id;
            menuTemp.userType = element.userType;
            menuTemp.label = element.label;
            menuTemp.shortLabel = element.label;
            menuTemp.action = element.action;
            menuTemp.link = element.link;
            menuTemp.locale = element.locale;
            menuTemp.icon = element.icon;
            menuTemp.position = element.position;
            menuTemp.published = element.published

            if (this.mainservice.userToken.role !== 'superuser' && element.published === true ) {
              this.insertMenuInArray( menuTemp, element.subItem )
            } else if (this.mainservice.userToken.role === 'superuser' ) {
              this.insertMenuInArray( menuTemp, element.subItem )
            }
          });
          this.sortMenuByPosition();
      });
  }

  insertMenuInArray( menuTemp, subItem ) {
    if ( subItem !== '') {
      this.menu.map(menuItem => {
        if (menuItem.label === subItem) menuItem.subItems.push(menuTemp);
      });
    } else {
      menuTemp.subItems = [];
      this.menu = [ ...this.menu, menuTemp]
    } 
  }

  sortMenuByPosition() {
    this.menu.sort((a, b) => {
      const positionA = parseInt(a.position, 10);
      const positionB = parseInt(b.position, 10);
      return positionA - positionB;
    });
  
    this.menu.forEach(item => {
      
      if (item.subItems && item.subItems.length > 0) {
        item.subItems.sort((a, b) => {
          const subPositionA = parseInt(a.position, 10);
          const subPositionB = parseInt(b.position, 10);
          return subPositionA - subPositionB;
        });
      }
    });
  }

  openPATAToken() {
    this.tokens = [];
    this.tokenExists = false;
    this.tokenUser = '';
    this.tokenData = '';
    this.tokenDate = '';

    this.mainservice.getPATAToken()
      .subscribe(res => {
        const retorno: any = res;
          retorno.forEach(element => {
            const tokenTemp = new Token();
            tokenTemp.id = element._id;
            tokenTemp.username = element.username;
            tokenTemp.token = element.token;
            tokenTemp.createdAt = element.createdAt;
            this.tokens.push(tokenTemp);
            this.tokenExists = true;
            this.tokenUser = element.username;
            this.tokenData = element.token;
            this.tokenDate = element.createdAt;
          });
      });
    this.getPATAToken.open();
  }

  openCompileKey() {
    this.compileKey = {
      "payload": {
        "iss": "",
        "sub": "",
        "auth": "",
        "exp": ""
      },
      "token": ""
    };
    
    this.mainservice.getCompileToken().subscribe(data => {
      this.compileKey = data;
      
    });

    this.getCompileKey.open();
  }

  generatePATAToken() {
    if ( this.tUsername !== '' && this.tPassword !== '' ) {
      this.mainservice.createPATAToken(this.tUsername, this.tPassword )
      .subscribe(res => {
        this.tokenData = res;
      });
    }
  }

  deletePATAToken() {
    this.mainservice.deletePATAToken(this.tokens[0].id)
      .subscribe(res => {
        this.openPATAToken();
      });
  }

}
